// Variable Overrides///

$light-green: #8DC73F;

$body-bg: #f6f6f6;
$border-grey: #ebebeb;
$white: #ffffff;
$black: #000000;
$grey: #5d5d5d;
$light-grey: #d4d4d4;
$medium-grey: #929292;
$red: #c33029;
$orange: #dd4f30;
$blue: #007bff;

$semi-bold: 600;
$bold: 700;
$primary : #5e5e5e;
$theme-colors: (
    "primary": $primary, 
    "medium-grey": $medium-grey,
    "light-grey": $light-grey,
    "black": $black,
    "orange": $orange,
    "red": $red

);
.text-orange { color: $orange!important; }
.text-primary { color: $primary!important; }
.border-primary { border-color: $primary!important; }
.text-medium-grey { color: $medium-grey!important; }
.text-light-grey { color: $light-grey!important; }
.text-black { color: $black!important; }
.text-red { color: $red!important; }
.bg-red { background-color: $red!important; }
$box-shadow-round: 0 0 1rem rgba(0, 0, 0, 0.075);

$font-family: 'Open Sans', sans-serif;


.jumbotron{
    background-color: $white !important;
    border-top: 3px solid rgb(219,219,219);
}
 
////////////////////////////////////////////////////

.carousel-left {
    width: 50px;
    height: 95px;
    background: transparent;
    z-index: 2;
    left: 0;
    padding-top: 1px;
    cursor: pointer;


}
.carousel-right {
    width: 45px;
    height: 95px;
    background: transparent;
    z-index: 2;
    right: 0;
    padding-top: 1px;
    cursor: pointer;

}
.on-hover {
    height: 93px;
    padding: 20px;
    display: none;
    font-size: 50px;
    background: rgba(197, 186, 186, 0.17);
    opacity: .7;
}
.zi-1 {
    z-index: 1;
}
#main-carousel-container .on-hover.show{
    display: block; 
}
#myBtn {
    padding-left: 9px;
    padding-right: 9px;
    display: none;
    position: fixed;
    bottom: 150px;
    right: 50px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #CBCBCB;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  #myBtn:hover {
    background-color: $red;
  }

  .fa-angle-right{
      float: right!important;
  }

  .fa-angle-left{
    float: left!important;
}

  .top-button {
    font-size: 30px;
    font-weight: 900;
  }


/////////////////////////////////////////////////////

// Bootstrap Sass Files
//@import "node_modules/bootstrap/scss/bootstrap";




//app styles

    *{
        font-family: $font-family;
    }
    .app-font {
        font-family: $font-family;
    }

    html{
        // background-color: $body-bg;
        color: $grey;
    }
    html, body{
        font-size:12px;
    }
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
        font-weight: $semi-bold;
    }
    body {
        background-color: $body-bg;
    }

    nav#main-nav{
        background-color: $white;
    }
    .shadow-md {
        box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
    .red{
        color: $red;
    }
    .nostyle{
        outline:none !important;
        border:none;
        // appearance: none; -moz-appearance: none; -ms-progress-appearance: none; -webkit-appearance: none;
        background: transparent;
        padding:0;
    }
    .rounded-18{
        border-radius:18px;
    }
    .truncate-v2{
    }
    .white-space-normal{white-space: normal!important;}
    .text-nowrap{
        // over-riding bootstrap
        line-height:1rem!important;
        overflow:hidden!important;
        text-overflow: ellipsis!important;
    }
    .jc-inner-page .custom-clamp-text h4.text-nowrap{line-height:1.5em!important; max-height:1.5em!important}
    .text-nowrap-no-lh{
        overflow:hidden!important;
        text-overflow: ellipsis!important;
    }
    #page .videos-collection-container .videos-collection .vc-header .vc-title.text-nowrap,
    #page .videos-collection-container .videos-collection .vc-header .vc-all{
        // line-height:1.5rem!important;font-size:1.5rem;
    }
    .d-flex > *{min-width:0;}
    .d-flex.overflow-x-auto > *,
    .flex-mw-auto > *, mw-auto{min-width:auto!important;}
    .mh-auto,
    .jc-inner-page h5.mh-auto, h4.mh-auto, h3.mh-auto{max-height:unset!important}
    
    .cover{
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    #pleaseWait{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99999;
        background: #ffffffe6;
        text-align: center;
        
        img{
            margin-top:30vh;
            display: inline-block;
            max-width:80%;
        }
    }

    #main-nav{
        // *{transition-property: none !important;}
        // .navbar-collapse{transition-property: ease!important;}
        .container{
            // height:64px;

            .telco-logo{
                img{
                    height: 35px;
                }
            }

            .navbar-brand{
                img.logo{
                    width:59px;
                }
            }

            .navbar-toggler {
                border: none;
                outline: none;
            }
            .navbar-toggler i {
                color: $black;
            }

            ul{
                font-size: 1.3em;
                font-weight: $semi-bold;
            
                li{
                    .active{
                        font-weight:$bold;
                    }
                }
            }

            .user{
                color: $black; font-size:1.7em;
                img{
                    height:22px;
                }
            }

            .navbar-nav{
                .active{
                    .nav-link{
                        color: $black;
                        font-weight: $bold;
                    }
                } 
                .nav-link{
                    color: $grey;
                }
                .nav-link:hover{
                    color: $black;
                } 
            }
            
            

        } //.contaqiner
    }// #main-nav

    #main-carousel-container{
        height: 95px;
        overflow:hidden;
        .cardss{
            height:115px;
            white-space: nowrap;
            overflow-x: scroll;
            overflow-y:hidden;
            // -webkit-overflow-scrolling: touch;
            // cursor: move;
        }
        .card{
            // width: 18rem;
            width: 216px;
            height:95px;
            display: inline-block;
            border-radius:5px;
            margin-right: 2px;
            .card-body{
                font-size: 0.9em;
            }
            .team1, .team2{
                .float-left{
                }
            }
            .card-body.topline{
                font-size: 0.9rem;
                height: 40px;
                color: $medium-grey;
                white-space: normal;
                // padding-top: 3px!important;
                .float-left{

                }
                .float-left{
                    font-weight: $semi-bold;
                }
            }
        }
    }//main-carousel-container

    #page{
        // margin-bottom:93px!important;
        a{text-decoration: none}
        .left-section{
            padding-left:15px;
            .featured-series{
                ul{
                    padding:0;
                    margin-left:17px;
                    -webkit-padding-start: 0;
                    list-style-position: inside;
                    list-style: none; /* Remove default bullets */
                    font-size:1em;
                    li{
                        // padding: 10px 0;
                        position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
                        /* use padding-bottom instead of margin-bottom.*/ 
                        margin-bottom: 0; /* This overrides previously specified margin-bottom */
                        padding-bottom: 1.6em;
                        a{
                            color: $black;
                            text-decoration: none;
                        }
                    }
                    li::after{
                        /* bullets */
                        // content: url('http://upload.wikimedia.org/wikipedia/commons/thumb/3/30/RedDisc.svg/20px-RedDisc.svg.png');
                        // content: "\2022";
                        // position: absolute;
                        // left: -26px; /*adjust manually*/
                        // top: 0px;
                        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                        color: $medium-grey; /* Change the color */
                        font-weight: bold; /* If you want it to be bold */
                        display: inline-block; /* Needed to add space between the bullet and the text */ 
                        width: 1em; /* Also needed for space (tweak if needed) */
                        position: absolute;
                        left: -18px; /*adjust manually*/
                        top: -5px;
                        font-size: 16px;
                    }
                    li::before{
                        // content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
                        // color: $light-grey; /* Change the color */
                        // font-weight: bold; /* If you want it to be bold */
                        // display: inline-block; /* Needed to add space between the bullet and the text */ 
                        // width: 1em; /* Also needed for space (tweak if needed) */

                        /* lines */
                        content:"";
                        position: absolute;
                        left: -16px; /* adjust manually */
                        border-left: 1px dotted $medium-grey;
                        height: 100%;
                        width: 1px;
                        top:6px;
                    }
                    li:first-child:before {
                        /* first li's line */
                        top: 10px; /* moves the line down so that it disappears under the bullet. Adjust manually */
                    }
                    li:last-child:before {
                        /* last li's line */
                       height: 0px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
                    }
                }
            }
            .latest-updates{
                .card-body{
                    color: $black;
                    border-bottom: 1px solid $light-grey;
                    font-size:1.2em;
                }
            }
        }
        #right-section{
            padding-right:15px;
        }
        #left-section{
            flex: 0 0 20%;
            max-width: 20%;
        }
        #main-section{
            flex: 0 0 54%;
            max-width: 54%;
            min-height: 100vh;
        }
        #right-section{
            flex: 0 0 26%;
            max-width: 26%;
        }
        #left-section, #main-section, #right-section{
            .card{
                // border:none;
                border-radius:0;
            }
        }

        #tweets-conatiner-right{
            .tweets-header{
                .tweets-heading{

                }
                .tweets-by{
                    font-size:1.2em;
                }
            }
            .tweet{
                .tweet-dp{width:50px; height:50px;}
                .tweet-birdie{font-size:22px;}
            }
        }

        .videos-collection-container{
            height:292px;
            overflow:hidden;
            border-bottom: 1px solid $light-grey;

            .truncate-v2{max-height: 40px;}
            .videos-collection{
                .vc-header{
                    .vc-title{
                        text-transform: uppercase;
                        color:$black;
                        font-size:1.5rem;
                        .vc-count{
                            display:block;
                            color: $grey;
                            text-transform: capitalize;
                            font-weight: $semi-bold;
                            font-size: 0.9em;
                        }
                    }
                    .vc-all{
                        text-transform: capitalize;
                        font-size:1.4rem;
                        a{
                            
                        }
                    }
                }
                .vc-list{
                    height: 258px;
                    white-space: nowrap;
                    overflow-x: scroll;
                    overflow-y: hidden;
                    -webkit-overflow-scrolling: touch;
                    cursor: move;
                    font-size:0;
                    .vc-item{
                        width:210px;
                        display:inline-block;
                        vertical-align: top;
                        white-space: normal;
                        .vc-item-img{
                            background-color:$light-grey;
                        }
                        .vc-item-h1{
                            font-size:14px;
                            color:$medium-grey;
                        }
                        .vc-item-h2{
                            // font-size:16px;
                            // color:$black;
                            // line-height: 1.3em;
                            font-size: 16px;
                            color: #000000;
                            line-height: 17px;
                            height: 36px;
                        }
                    }
                }
            }//.videos-collection
        }//.videos-collection-container
            
        .schedule{
            .schedule-date{}
            .schedule-detail{}
            .schedule-teams{
                img{
                    max-height: 100px;
                }
                .team1{

                }
                .team2{

                }
            }
            .schedule-footer{}
        }
        .schedule.upcoming{
            .schedule-date{}
            .schedule-detail{}
            .schedule-teams{
                img{}
                .team1{

                }
                .team2{

                }
            }
            .schedule-footer{

            }
        }
        button.grey-white.collapsed{
            background-color:#fff;
            .fa.fa-angle-down{opacity:1}
        }
        button.grey-white{
            background-color:#e7e7e7;
            .fa.fa-angle-down{opacity:0}
        }
    }//#page

    footer{
        background-color: $white;
        font-size: 1.2em;
        img{
            cursor:pointer;
        }
    }//footer

//app styles

//app media queries
    @media screen and (min-width: 1200px) {

        .container{
            max-width: 1595px;
        }
    }

    @media screen and (max-width: 991px) {
        .container{
            max-width:100%!important;
        }
        #main-carousel-container .on-hover.show{
            display: none; 
        }
        #main-nav{
            padding-left:0;
            padding-right:0;
            .container{
                .navbar-nav{
                    .nav-link{
                        font-size: 13px;
                        padding-top:0.8rem;
                        padding-bottom:0.8rem;
                    }
                    li{ }    
                }
                .navbar-brand{
                    img.logo{
                        width:35px;
                    }
                }
                .navbar-toggler{
                }
                .navbar-toggler i {
                    font-size:20px;
                    transform: scale(1, 0.65);
                    transition: all 0.5s linear 0s
                }
                .navbar-toggler.collapsed i{
                    transform: scale(1, 0.75);
                }
                .divider{
                    margin-left:-1rem; margin-right:-1rem;
                    height:1px;
                    border-top: 1px solid $border-grey;
                }
                .user {
                    font-size:22px!important;
                }
                ul{
                    font-size: 0.8em;
                    li{
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-bottom:1px solid $light-grey;
                    }
                }

            }//.container
        }// #main-nav

        #page{
            #main-section{
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 15px;
                padding-right: 15px;

                
            }
            .schedule{
                h1{font-size:1.85rem;}
                h3{font-size:1rem;}
                h5, h4, .h5{font-size:0.9rem;}
                .schedule-date{}
                .schedule-detail{}
                .schedule-teams{
                    img{max-height:45px;}
                    .team1{
    
                    }
                    .team2{
    
                    }
                }
                .schedule-footer{
    
                }
            }
        }//page

        footer{
            img{
                height: 40px;
            }
        }//footer

        //!!!!!!!!!!! omair siddiqi updates !!!!!!!!!!!!
        #main-nav .container .navbar-toggler{padding-left:8px;}
        #main-nav .container .user{padding-right:8px;}

        .card{
            > .p-3{ padding:8px !important; }
        }
        .card.p-3{ padding:8px!important; }
        
        .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto,
        .card.px-3, .card .px-3,
        #page #main-section, .container, 
        #main-nav .container .navbar-nav li { 
            padding-right:8px!important; padding-left:8px!important; 
        }

        #main-nav .container .divider, .row,
        #main-nav .container ul{ margin-right:-8px !important; margin-left:-8px!important; }


        .m-f-11{
            // 11px on 375px
            font-size:0.93rem!important;
        }
        .m-f-12{
            // 12px on 375px
            font-size:1rem!important;
        }

        .m-f-13{
            // 13px on 375px
            font-size:1.09rem!important;
        }
        .m-f-14{
            // 14px on 375px
            font-size:1.17rem!important;
        }
        //!!!!!!!!!!! omair siddiqi updates !!!!!!!!!!!!
    }
//app media queries

.sixteen-by-nine {
    padding-bottom: 56.25%;
}
.one-by-one {
    padding-bottom: 85%;
}
.one-by-one-square {
    padding-bottom: 100%;
}
.four-by-three {
    padding-bottom: 70%;
}
.bg-center-cover {
    background-size: cover;
    background-position: center;
}
.bg-center-contain {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.bg-transparent{
    background-color:transparent !important;
}
.w-120px {
    width: 120px;
}
.w-25px {
    width: 25px;
}
.w-20px {
    width: 22px;
}
.w-20 {
    min-width: 20%;
}
.w-30 {
    min-width: 30%;
}
.w-40 {
    min-width: 40%;
}
.w-45 {
    min-width: 45%;
}
.w-3rem {
    width: 3rem;
}
.h-3rem {
    height: 3rem;
}
.font-weight-semi-bold {
    font-weight: $semi-bold;
}
font-weight-bold{
    font-weight: $bold;
}
.custom-box {
    // -webkit-box-shadow: $box-shadow-round !important;
    // box-shadow: $box-shadow-round !important;
    // border-radius: 5px;
    border-bottom: 1px solid rgba(0,0,0,.125)
}
button:focus {
    outline: none!important;
    box-shadow: none!important;
}
.play-btn-lg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 6rem;
    color: #fff;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}
.play-btn-sm {
    font-size: 3.5rem;
}
.play-btn-xs{
    font-size: 2.5rem;
}
.overflow-x-auto {
    overflow-x: auto;
    h4, h5 {
        white-space: nowrap;
    }
}
.custom-ball-size {
    height: 2.3rem;
    width: 2.3rem;
}
a {
    color: $black;
    &:hover {
        text-decoration: none;
        color: $black;
    }
}
table { width: 100%; }
.custom-match-scorecard table {
    tr:first-child {
        background-color: $black;
        td {
            color: $white!important;
            font-weight: $semi-bold!important;
        }
    } 
    tr:nth-child(even) {
        background-color: $body-bg;
    }
}
.points-table tr:nth-child(even) {
    background-color: $body-bg;
}
[data-toggle="collapse"] .fa.videos-menu:before {
    content: "\f106";
}
[data-toggle="collapse"].collapsed .fa.videos-menu:before  {
    content: "\f107";
}

[data-toggle="collapse"] .fa.fa-bars:before {
    content: "\f0c9";
}

[data-toggle="collapse"].collapsed .fa.fa-bars:before  {
    content: "\f0c9";
}
.dropdown-toggle:after {
    content: "\f107";
    height: auto;
    width: auto;
    border: none;
    font-size: 1.5rem;
}
.rankings-dropdown:after {
    content: "\f142";
}
.dropdown-menu {
    border: none;
    -webkit-box-shadow: $box-shadow-round;
    box-shadow: $box-shadow-round;
}
.player-minimize .player-position {
    bottom: 123px;
    position: fixed;
    z-index: 1;
}
// h3, h4, h5 {
//     color: $black;
// }
.border-light {
    border-color: rgba(0,0,0,0.1)!important;
}
.border-red {
    border-color: $red!important;
}
.br-2rem {
    border-radius: 2rem!important;
}
.mw-11rem {
    min-width: 11rem;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
//   width: 30px;
//   height: 8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
//   left: 0;
  width: 30px;height:8px;margin-top:5px;
  right: 0;
  bottom: 0;
  background-color: $light-grey;
  -webkit-transition: .4s;
  transition: .4s;
  height: 12px;
  margin-top:4px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -3px;
    bottom: -4px;
    background-color: $medium-grey;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider:before {
//   background-color: $red;
  background-color: $light-green;
}
input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 1rem;
}

.slider.round:before {
  border-radius: 50%;
}
/*.font-sm {
    font-size: 0.8rem;
}
.jc-inner-page h3 {
    font-size: 1.2rem;
}
.jc-inner-page h4 {
    font-size: 1.1rem;
}
.jc-inner-page h5 {
    font-size: 1rem;
}*/

.jc-inner-page{
    button.d-flex{
        border:none;
    }
}
.jc-inner-page .custom-clamp-text {
    h5, h4, h3 {
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }
    h5 {
        line-height: 1.7rem;
        max-height: 3.4rem;
    }
    h4 {
        line-height: 1.95rem;
        max-height: 4rem;
    }
    h3 {
        line-height: 2.25rem;
        max-height: 4.5rem;
    }
}

.lh-initial{line-height: initial!important}

@media screen and (max-width: 991px) {
    .jc-inner-page h5 {
        // font-size: 0.8rem;
        //updates in omair'section
    }
    .jc-inner-page h4 {
        // font-size: 0.85rem;
        //updated in omair's comment section
    }
    .jc-inner-page h3 {
        font-size: 0.9rem;
    }
    .jc-inner-page .font-sm, p {
        font-size: 0.8rem;
    }
    .jc-inner-page h5, h4, h3 {
        line-height: 1.3rem!important;
        max-height: 2.6rem!important
    }
    .play-btn-lg {
        font-size: 3.5rem;
    }
    .play-btn-sm {
        font-size: 2.5rem;
    }
    .mw-11rem {
        min-width: 8rem;     
    }
    table td {
        font-size: 0.7rem;
        font-size: 0.9rem;
    }
}
#page{
    min-height: calc( 100vh - 238px);
}
.bbb-container { 
    cursor: move; 
}
.mw-2rem {
    min-width: 2rem;
}
.w-65px { width: 65px; }
.pl-30 { padding-left: 30px; }
/*Over riding for now*/
.truncate-v2 {
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
.text-secondary {
    color: $medium-grey!important;
}
.sub-input {
    background-color: $body-bg;
    padding: 1rem;
}
.sub-input:focus {
    outline: none;
}
.sub-btn {
    background-color: $black;
    padding: 1rem;
}
/*calender css*/
.ui-datepicker-calendar, .ui-datepicker-current, .ui-datepicker-trigger {
    display: none;
}
.fs-1p2 { font-size: 1.2em; }
.fs-1p3 { font-size: 1.3em; }
.w-10 { width: 10%; }
.w-25 { width: 25%; min-width: 25%; }
#main-section {
    // min-height: 100vh;
}
.min-height-80vh {
    min-height: 80vh;
}
.main-loader img {
    margin: 90px auto;
}
.overs-text {
    font-size: 0.8rem;
}
.card {
    transition: opacity 2s;
}
.h-transition {
    opacity: 0;

}
.v-transition {
    opacity: 1;
    transition: 0.5s ease-in;
}
.h-r-dd{
    //homescreen ranking dropdown
    button.btn-light.active,
    button.btn-light:hover{
        background-color:$light-grey!important;
    }
}
.news-bulletins li {
    margin-top: 10px;
    font-size: 16px;
    margin-left: 20px;
    // list-style: none;
}
.news-bulletins li:first-child {
    margin-top: 0px;
}
.mw-75px {
    min-width: 75px;
}
.series-heading {
    white-space: nowrap!important;
    display: inline!important;
}
//Embedded tweet css
blockquote.twitter-tweet {
    overflow: hidden;
    color: #1c2022;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 5px;
    width: 100%;
    padding: 1.25rem 1.25rem 0.725rem 1.25rem;
  }
  blockquote.twitter-tweet:before {
    content: none;
  }
  blockquote.twitter-tweet p {
    white-space: pre-wrap;
    font: 13px/1.4 Helvetica, Roboto, 'Segoe UI', Calibri, sans-serif;
  }
  blockquote.twitter-tweet a {
    color: #2b7bb9;
  }
  blockquote.twitter-tweet a:visited {
    color: #2b7bb9;
  }
  
.latest-updates a, .news-bulletins a, .featured-series a, .custom-clamp-text h4, .custom-clamp-text h5, .custom-clamp-text h3, .video-card-links h3 {
    // color: darken($blue,10%)!important;
      &:hover {
          color: darken($blue,5%)!important;      
      }
}
a .custom-clamp-text {
    h3 {
        font-size: 18px;
        &:hover {
            color: darken($blue,5%)!important;      
        }
    }
}
.custom-box h4 {
    font-size: 14px;
}

.series-card-heading a, .series-btn-menu a, .scorecard-link .custom-box, a.main-carousel {
      &:hover {
          background-color: lighten($light-grey,12%)!important;      
      }
}
.selected-container {
    background-color: lighten($light-grey,12%)!important;      
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
.video-card-right-section h5 {
    font-size: 1rem;
    line-height: 1.3rem!important;
}
.text-dark-grey {
    color: #333;
}
.schedule h3 {
    font-size: 16px;
}
.schedule .w-120px {
    width: 75px;
}
@media(min-width: 992px) {
    #page {
        padding: 0;
        max-width: 1150px;
    }
    #page #main-section {
        max-width: calc(54% - 20px);
        margin: 0 10px;
    }
    nav#main-nav .container {
        padding: 0;
        max-width: 1150px;
    }
    #main-carousel-container {
        max-width: 1150px;
        padding: 0;
    }
}
@media(max-width: 991px) {
    .video-card-links .w-25 {
        min-width: 30%;
    }
    .stories-list-card .w-30 {
        min-width: 35%;
    }
    .schedule h3 {
        font-size: 13px;
    }
    .schedule .w-120px {
        width: 55px;
    }
}